<template>
  <div id="administratorToolsMain" v-if="isOwner">
    <CRow id="administratorToolsHeaderRow" class="mt-3">
      <CCol id="administratorToolsHeaderCol" md="12" class="text-center">
        <h2 id="administratorToolsTitle" class="font-weight-medium text-dark">
          {{ $t('administratorTools') }}
        </h2>
        <h6 id="administratorToolsDescription" class="text-description">
          {{ $t('toolsSetting') }}
        </h6>
      </CCol>
    </CRow>
    <CCol id="administratorToolsAlertCol" col="12" style="margin-top: 1rem;">
      <CAlert id="administratorToolsAlert" color="info">
        <i class="fi fi-rr-info mr-1"></i> {{ $t('adminToolDesc') }}
      </CAlert>
    </CCol>
    <hr id="administratorToolsDivider" />
    <CRow id="administratorToolsCardRow">
      <CCol
        v-for="(item, index) in items"
        :key="item.objectId"
        :id="'administratorToolsCardColItem' + (index + 1)"
        md="6"
        lg="4"
        sm="6"
      >
        <CCard id="administratorToolsCard" class="card-unlock bg-white" style="border-radius: 10px;">
          <CCardBody id="administratorToolsCardBody">
            <CRow class="border-bottom">
              <CCol id="administratorToolsCardHeaderCol" lg="12" sm="12" col="12">
                <h5
                  :id="'administratorToolsCardTitle' + (index + 1)"
                  class="font-weight-medium"
                  style="padding-top: calc(0.375rem + 1px);padding-bottom: calc(0.375rem + 1px);white-space: nowrap;"
                >
                  {{ $t('posNumber') }} - {{ item.posNumber }}
                </h5>
              </CCol>
            </CRow>
            <table id="administratorToolsTable" class="table table-borderless mt-2">
              <tr id="administratorToolsTableRow">
                <td id="administratorToolsDeviceNameLabel" style="width:50%">
                  <label class="text-left text-dark font-weight-medium">
                    <span>
                      {{ $t('deviceName') }}
                    </span>
                  </label>
                </td>
                <td :id="'administratorToolsDeviceNameValue' + (index + 1)" class="text-right">
                  <label class="text-dark color-input-unlock">
                    {{ item.deviceName }}
                  </label>
                </td>
              </tr>
              <tr id="administratorToolsLastSyncRow">
                <td id="administratorToolsLastSyncLabel">
                  <label class="text-left text-dark font-weight-medium">
                    <span>
                      {{ $t('lastSyncProduct') }}
                    </span>
                  </label>
                </td>
                <td :id="'administratorToolsLastSyncValue' + (index + 1)" class="text-right">
                  <label class="text-dark text-right color-input-unlock">
                    {{ item.lastSyncProduct }}
                  </label>
                </td>
              </tr>
              <tr id="administratorToolsVersionRow">
                <td id="administratorToolsVersionLabel">
                  <label class="text-left text-dark font-weight-medium">
                    <span>
                      {{ $t('version') }}
                    </span>
                  </label>
                </td>
                <td :id="'administratorToolsVersionValue' + (index + 1)" class="text-right">
                  <label class="text-dark text-right color-input-unlock">
                    {{ item.version }}
                  </label>
                </td>
              </tr>
              <tr id="administratorToolsStatusRow">
                <td id="administratorToolsStatusLabel" col="6">
                  <label class="text-left text-dark font-weight-medium">
                      {{ $t('posstatus') }}
                  </label>
                </td>
                <td :id="'administratorToolsStatusValue' + (index + 1)" class="text-right">
                  <CRow>
                    <!-- <CCol id="administratorToolsSwitchCol">
                      <CSwitch
                        v-if="item.status === 'Online'"
                        :id="'administratorToolsSwitchOnline' + (index + 1)"
                        color="success"
                        size="lg"
                        disabled
                        checked
                        labelOn="On"
                        labelOff="Off"
                      />
                      <CSwitch
                        v-else
                        :id="'administratorToolsSwitchOffline' + (index + 1)"
                        color="success"
                        size="lg"
                        disabled
                        labelOn="On"
                        labelOff="Off"
                      />
                    </CCol> -->
                    <CCol id="administratorToolsStatusTextCol" class="col-12">
                      <CBadge
                        v-if="item.status === 'Online'"
                        :id="'administratorToolsStatusOnline' + (index + 1)"
                        color="success"
                        style="font-size: 12px; padding:5px; border-radius: 10px;"
                      >
                        {{ $t('isBeingToUsed') }}
                      </CBadge>
                      <CBadge
                        v-else
                        :id="'administratorToolsStatusOffline' + (index + 1)"
                        color="light"
                        style="font-size: 12px; padding:5px; border-radius: 10px;"
                      >
                        {{ $t('notInUse') }}
                      </CBadge>
                    </CCol>
                  </CRow>
                </td>
              </tr>
            </table>
          </CCardBody>
          <CCardFooter id="administratorToolsCardFooter" >
            <div id="administratorToolsUnlockDiv" v-if="item.enabled === true">
              <CButton
                :id="'administratorToolsUnlockButton' + (index + 1)"
                block
                class="btn-danger"
                v-on:click="showPopup(item)"
                v-if="item.uuid !== ''"
              >
                {{ $t('unlock') }}
              </CButton>
              <CButton
                :id="'administratorToolsDisabledUnlockButton' + (index + 1)"
                block
                color="secondary"
                disabled
                v-else
              >
                {{ $t('unlock') }}
              </CButton>
            </div>
            <div id="administratorToolsDisabledDiv" v-else>
              <CButton
                :id="'administratorToolsDisabledButton' + (index + 1)"
                block
                color="secondary"
                disabled
              >
                {{ $t('unlock') }}
              </CButton>
            </div>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      id="administratorToolsModal"
      :show.sync="popupModal"
      centered
      :title="$t('unlock')"
      color="danger"
      :footer="footer"
    >
      <div id="administratorToolsModalBody" v-if="!isSuccess">
        <h4 id="administratorToolsModalTitle" class="text-center">
          {{ $t('unlockTitle', { posNumber: itemsUnlock.posNumber }) }}
        </h4>
        <p id="administratorToolsModalDescription" v-html="$t('unlockDescription')" class="text-center">
        </p>
      </div>
      <div id="administratorToolsModalSuccessBody" v-else>
        <h4 id="administratorToolsModalSuccessTitle" class="text-center">
          {{ $t('unlockSuccess') }}
        </h4>
      </div>
      <template #footer>
        <CRow id="administratorToolsModalFooterRow" class="justify-content-center col-md-12">
          <CCol id="administratorToolsModalConfirmCol" col="5" lg="4">
            <CButton
              id="administratorToolsModalConfirmButton"
              color="danger"
              block
              v-if="loadingButton === true && !isSuccess"
              v-on:click="getUnlockPOS"
            >
              {{ $t('unlock') }}
            </CButton>
            <CButton
              id="administratorToolsModalSavingButton"
              block
              color="danger"
              v-else-if="loadingButton === false && !isSuccess"
              disabled
            >
              <CSpinner id="administratorToolsModalSpinner" color="white" size="sm" /> {{ $t('save') }}
            </CButton>
          </CCol>
          <CCol id="administratorToolsModalSpacerCol" col="1" lg="2"></CCol>
          <CCol id="administratorToolsModalCancelCol" col="5" lg="4">
            <CButton
              id="administratorToolsModalCancelButton"
              block
              color="light"
              @click="popupModal = false"
              v-if="!isSuccess"
            >
              {{ $t('cancel') }}
            </CButton>
          </CCol>
          <CCol col="6">
          <CButton block color="light" v-if="isSuccess" @click="fetchPOSData">
            {{ $t('close') }}
          </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>
  </div>
  <div id="administratorToolsNoAccess" v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/local'
import permis from '@/util/permission'
import { CBadge, CRow } from '@coreui/vue'

export default {
  data() {
    return {
      data: [],
      itemsUnlock: {},
      loadingButton: true,
      popupModal: false,
      footer: false,
      isSuccess: false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'allPOS', 'isOwner']),
    isPermission() {
      return permis.findPermissionRead('setting', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    items() {
      let data = this.allPOS
      let detail = []
      for (let i = 0; i < data.length; i++) {
        let posNumber = ''
        let lastSyncProduct = ''
        let version = ''
        let uuid = ''
        let deviceName = ''
        let status = 'Offline'

        if (data[i].posNumber !== undefined) {
          posNumber = data[i].posNumber
        }

        if (data[i].lastSyncProduct !== undefined) {
          lastSyncProduct = moment(String(data[i].lastSyncProduct)).format(
            'DD/MM/YYYY HH:mm'
          )
        }

        if (data[i].version !== undefined) {
          version = data[i].version
        }

        if (data[i].uuid !== undefined) {
          uuid = data[i].uuid
          status = 'Online'
        }

        if (data[i].deviceName !== undefined) {
          deviceName = data[i].deviceName
        }
        detail.push({
          posNumber: posNumber,
          lastSyncProduct: lastSyncProduct,
          version: version,
          uuid: uuid,
          deviceName: deviceName,
          objectId: data[i].objectId,
          status: status,
          enabled: data[i].enabled || false,
        })
      }
      return detail
    },
  },
  mounted() {
    this.$store.dispatch('getAllPOS')
  },
  methods: {
    fetchPOSData() {
      this.$store.dispatch('getAllPOS').then(() => {
        this.popupModal = false
      })
    },
    showPopup(item) {
      this.itemsUnlock = item
      this.isSuccess = false
      this.popupModal = true
    },
    getUnlockPOS() {
      this.loadingButton = false
      this.loading = true
      const uid = `${localStorage.getItem('shopsUid')}`
      const objectId = this.itemsUnlock.objectId
      axios
        .get('/api/v1.0/' + uid + '/Shop/unlockpos/' + objectId)
        .then((res) => {
          if (res.data.error.code === 0) {
            this.$store.dispatch('getAllPOS')
          }
          this.isSuccess = true
          // this.popupModal = false
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style>
.card-unlock {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 21 / 20%) !important;
}
</style>
